<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseButton from '@/components/BaseButton'

  const { mapGetters, mapActions } = createNamespacedHelpers('products')

  /** Pagination button that appears below the PLP listing grid to allow users to load more products.
   * Displays the total amount, total showing and amount left on next click. */

  export default {
    name: 'ProductCuratedPagination',

    components: {
      BaseButton,
    },

    props: {
      count: {
        type: Number,
        default: 0,
      },
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getPaginationCuratedData', 'isLastCuratedPage']),

      shownQuantity: function () {
        return (
          '<span data-cy="currentNumberOfProducts">' +
          this.getPaginationCuratedData.current +
          '</span>'
        )
      },

      totalQuantity: function () {
        return (
          '<span data-cy="totalNumberOfProducts">' + this.getPaginationCuratedData.total + '</span>'
        )
      },
    },

    methods: {
      /** Vuex action methods */
      ...mapActions(['nextCuratedPage']),
    },
  }
</script>

<template>
  <nav
    aria-label="Product pagination"
    class="flex flex-col justify-center items-center pb-0 pt-16 md:pb-12 border-t border-gray-300 | md:flex-row md:justify-between || wrapper"
    :class="`${isLastCuratedPage ? 'hasNoLoadMore' : ''}`"
  >
    <span
      class="text-gray-500 || desktop invisible"
      v-html="'Showing ' + shownQuantity + ' Products'"
    />

    <transition name="fade">
      <BaseButton
        v-if="!isLastCuratedPage"
        :color="'gray'"
        :rounded="true"
        class="mb-2 | md:mb-0"
        @button-click="nextCuratedPage"
      >
        See More
      </BaseButton>
    </transition>

    <span
      class="text-gray-500 || desktop invisible"
      aria-label="total products"
      v-html="'Total ' + totalQuantity + ' Products'"
    />

    <span
      class="block text-xs text-gray-500 || mobile invisible"
      aria-current="true"
      aria-label="current products"
      v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
    />
  </nav>
</template>

<style scoped lang="postcss">
  .wrapper {
    min-height: 123px;

    &.hasNoLoadMore {
      height: 0;
      min-height: 0;
      padding: 0;
      overflow: hidden;
    }
  }

  .desktop {
    @apply hidden;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .wrapper {
      min-height: 97px;
    }

    .mobile {
      @apply hidden;
    }

    .desktop {
      @apply inline-block;
    }
  }
</style>
