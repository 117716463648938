<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseTileGridLoader from '@/components/BaseTileGridLoader'
  import ProductTile from '@/components/products/ProductTile'
  import ProductCuratedPagination from '@/components/products/ProductCuratedPagination'

  const { mapGetters, mapMutations } = createNamespacedHelpers('products')

  /** The product listing output grid. This component displays Product Tile components in a paginated format and
   * includes a loading animation before all of the products are loaded into the app via Vuex */

  export default {
    name: 'ProductTileGridCurated',

    components: {
      BaseTileGridLoader,
      ProductTile,
      ProductCuratedPagination,
    },

    props: {
      products: {
        type: Array,
        default: () => [],
      },
      /** Whether or not to hide the label "Price on Request" **/
      hidePriceOnRequestLabel: {
        type: Boolean,
        required: false,
        default: false,
      },
      /** Whether or not to hide the price line all together. **/
      hidePrice: {
        type: Boolean,
        required: false,
        default: false,
      },
      destination: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        loader: {
          width: 0,
          height: 0,
        },
        isLoaded: false,
      }
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getIsLoading', 'getFirstLoad', 'getBrowsingSnapshot', 'getPaginationData']),
      /** Returns the loaders emitted height */
      loaderHeight: function () {
        return this.loader.height
      },
      /** Create the loader CSS style height string */
      loaderStyles: function () {
        return 'height:' + this.loaderHeight + 'px;'
      },
    },

    watch: {
      getIsLoading(item) {
        if (!item) {
          if (this.products.length === 0) {
            this.isLoaded = true
          }
        }
      },
      products(items) {
        if (!this.getIsLoading) {
          items.length === 0 ? (this.isLoaded = true) : (this.isLoaded = false)
        }
      },
    },

    updated() {
      this.scrollToSnapshotPosition()
    },

    methods: {
      /** Vuex mutations */
      ...mapMutations(['setFirstLoad']),
      /** Sets the loader components height */
      setLoaderHeight(loader) {
        this.loader.height = loader.height
      },
      /** Scrolls the page to the last snapshot position in local storage */
      scrollToSnapshotPosition() {
        if (this.getFirstLoad && this.getBrowsingSnapshot) {
          const yPos = this.getBrowsingSnapshot.scrollPosition
          window.scrollTo(0, yPos)
          this.setFirstLoad(false)
        }
      },
    },
  }
</script>

<template>
  <div
    class="relative py-6 md:col-span-2 lg:col-span-3"
    :style="getIsLoading ? 'height: ' + (loaderHeight + 48) + 'px' : ''"
  >
    <transition name="fade">
      <div v-if="getIsLoading" class="absolute z-10 || loader" :style="loaderStyles">
        <BaseTileGridLoader @resize="setLoaderHeight" />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="!getIsLoading">
        <section v-if="products.length">
          <div
            class="grid grid-cols-2 gap-x-4 gap-y-8 | md:grid-cols-3 | lg:grid-cols-4"
            role="feed"
          >
            <ProductTile
              v-for="product in products"
              :key="product.id"
              v-bind="product"
              :destination="'curated'"
              :hide-price="hidePrice"
            />
          </div>
          <ProductCuratedPagination class="mt-4 | md:mt-6" :count="products.length" />
        </section>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="postcss">
  .loader {
    top: 16px;
    right: 16px;
    left: 16px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .loader {
      top: 24px;
      right: 24px;
      left: 24px;
    }
  }
</style>
