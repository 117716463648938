<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import BasePageHeader from '@/components/BasePageHeader'
  import ProfileDescription from '@/components/profiles/ProfileDescription'
  import ProductFilter from '@/components/products/ProductFilter'
  import ProductTileGrid from '@/components/products/ProductTileGrid'
  import BrandList from '@/components/brands/BrandList'
  import FilterIcon from '@/images/icons/filter-icon.svg'
  import ExternalIcon from '@/images/icons/external-icon.svg'
  import IconClose from '@/images/icons/clear-filters.svg'

  /** This is the main view template for the Brand Detail Page. It mostly pulls in other components. */

  export default {
    name: 'BrandDetailPage',

    components: {
      BasePageHeader,
      ProfileDescription,
      ProductFilter,
      ProductTileGrid,
      BrandList,
      FilterIcon,
      IconClose,
      ExternalIcon,
    },

    props: {
      tradeOnlyBrandsAll: {
        type: Array,
        default: () => [],
      },
      curatedCollections: {
        type: Array,
        default: () => [],
      },
      ifNotAvailableProducts: {
        type: Array,
        default: () => [],
      },
      /** The Craft brand entry slug */
      externalLink: {
        type: String,
        default: '',
      },
      brandPage: {
        type: Boolean,
        default: false,
      },
      externalLinkText: {
        type: String,
        default: '',
      },
      priceFilters: {
        type: Array,
        default: () => [],
      },
      curCats: {
        type: Array,
        default: () => [],
      },
      brand: {
        type: String,
        required: true,
      },
      /** The brand title/name */
      title: {
        type: String,
        required: true,
      },
      dimensionFilters: {
        type: Array,
        default: () => [],
      },
      /** The brand hero image */
      image: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** The brand city */
      city: {
        type: String,
        required: false,
        default: null,
      },
      /** The brand description/body */
      description: {
        type: String,
        required: false,
        default: null,
      },
      downloads: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** The brand website */
      website: {
        type: String,
        required: false,
        default: null,
      },
      /** An array of product IDs of curated products for this brand */
      curatedProducts: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** An array of product category ids this brand */
      productCategory: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** The options for the filters */
      filterOptions: {
        type: Object,
        default: null,
      },
      /** The handles of filters to hide */
      hiddenFilters: {
        type: Array,
        default: null,
      },
      /** An initial status filter to set on page load */
      initialFilter: {
        type: String,
        default: null,
        // validator: (propValue) => {
        //   const validTypes = ['quickShip', 'inShowroom']
        //   return validTypes.indexOf(propValue) > -1
        // },
      },
      /** Is the user logged in as a trade account user */
      isTradeAccount: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        clicked: false,
        selectedFilters: [],
        clearedFilter: [],
        filterBarOpen: false,
        moreWidth: false,
        topOffset: 0,
        isCleared: 0,
        readmore: false,
        moreWidth: true,
        isVisible: false,
        giveOpacity: false,
      }
    },

    watch: {
      selectedFilters(item) {
        if (item.length === 0) {
          this.isVisible = false
        } else {
          this.isVisible = true
        }
      },
      filterValues() {
        this.filterValues.quickShip ? (this.clicked = true) : (this.clicked = false)
      },
    },

    computed: {
      /** Vuex getter methods */
      ...mapState('products', ['filterValues']),
      ...mapGetters('brands', ['getTotalItems', 'getStructuredItems']),
      ...mapGetters('products', [
        'getProducts',
        'getPaginatedCuratedProducts',
        'getSortOptions',
        'getSort',
        'getPaginationData',
        'getIsLoading',
        'getSelectedOption',
        'getFilters',
      ]),
      shownQuantity: function () {
        return (
          '<span data-cy="currentNumberOfProducts">' + this.getPaginationData.current + '</span>'
        )
      },

      totalQuantity: function () {
        return '<span data-cy="totalNumberOfProducts">' + this.getPaginationData.total + '</span>'
      },
      displayUrl: function () {
        return this.website
          ? this.website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
          : ''
      },
    },

    created() {
      this.$store.dispatch('brands/setTradeAccount', this.isTradeAccount)
      this.$store.dispatch('brands/setTradeOnlyBrands', this.tradeOnlyBrandsAll)
      this.$store.dispatch('products/isBrandPage', true)
      this.$store.dispatch('products/setCuratedCategories', this.curCats)
      this.$store.dispatch('products/setCuratedCollections', this.curatedCollections)
      this.$store.dispatch('products/isRandomized', true)
      /** Initialize the filters */
      this.$store.dispatch('products/initFilters', {
        filterOptions: this.filterOptions,
        hiddenFilters: this.hiddenFilters,
      })

      let selectedFilter = ''

      if (this.initialFilter) {
        if (this.initialFilter === 'quickship') {
          selectedFilter = 'quickShip'
        } else {
          selectedFilter = this.initialFilter
        }
      }

      this.$store.dispatch('products/initPriceFilters', this.priceFilters)
      this.$store.dispatch('products/initDimensionFilters', this.dimensionFilters)
      /** If an initial filter is defined (either quickShip or inShowroom) set it */
      if (selectedFilter !== '') {
        this.$store.dispatch('products/setChosen', this.initialFilter)
        if (this.initialFilter === 'quickship') {
          this.clicked = true
        }
        this.$store.dispatch('products/setFilterValue', {
          filter: selectedFilter,
          value: true,
        })
      }

      const sortCat = this.productCategory
        .filter((item) => item.sortBy === false)
        .map((item) => item.value)
      /** Dispatch actions in the Vuex store to get the products by brand */
      this.$store.dispatch('products/initProducts', {
        brand: this.title,
        curated: this.curatedProducts,
        productCategories: sortCat,
        isTradeAccount: this.isTradeAccount,
      })

      /** Dispatch actions in the Vuex store to get all brands */
      this.$store.dispatch('brands/initItems')
    },

    mounted() {
      this.setSort(this.getSort)
    },

    methods: {
      quickShipChecked() {
        this.clicked = !this.clicked
        const filter = this.getFilters.filter((item) => item.handle === 'quickShip')[0]

        if (this.clicked) {
          const url = new URL(window.location)
          url.searchParams.set('s', filter.handle.toLowerCase())
          window.history.pushState({}, '', url)
        } else {
          const url = new URL(window.location)
          url.searchParams.delete('s')
          window.history.pushState({}, '', url)
        }

        this.$store.dispatch('products/setFilterValue', {
          filter: 'quickShip',
          value: this.clicked,
        })
      },
      showSidebarFilters() {
        this.moreWidth = !this.moreWidth
        this.giveOpacity = true
      },
      ...mapActions('products', [
        'setSort',
        'clearFilters',
        'clearAllFilters',
        'remoteSelectedFilter',
      ]),
      ...mapGetters(['getUsedFilters']),
      scrollToElementOnClick(target) {
        const element = document.querySelector(`#item-${target}`)
        if (element) {
          let yOffset = 0
          let offsetHeights = 0
          const headerHeight = document.querySelector('#ref-header').offsetHeight
          const noticeElement = document.querySelector('#notice')
          if (noticeElement !== null) {
            offsetHeights = noticeElement.offsetHeight
            yOffset = headerHeight + offsetHeights + 20
          } else {
            yOffset = headerHeight + 20
          }

          const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset
          window.scrollTo({ top: y, behavior: 'smooth' })
        }
      },
    },
  }
</script>

<template>
  <main id="main-content">
    <article class="pt-10">
      <!--<BasePageHeader :heading="title" :prefix="'Brand'" />-->

      <ProfileDescription
        :image="image"
        :city="city"
        :website="website"
        :downloads="downloads"
        :heading="title"
        :textLength="description.length"
        @readmore="readmore = $event"
      >
        <div
          class="space-y-4"
          v-html="`${readmore ? description : description.substring(0, 900)}`"
        ></div>
      </ProfileDescription>

      <div v-if="getProducts.length === 0" class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
        <div v-if="!getIsLoading">
          <div class="border-t border-b border-gray-300 pt-8 pb-12 mb-12 text-center">
            <h3 class="mb-4 uppercase font-bold text-center block text-xl">Coming soon</h3>
            <p class="block mb-2"
              >Visit <a :href="website" class="underline" target="_blank">{{ displayUrl }}</a> for
              product preview.</p
            >
            <p>For pricing inquiries or more information, please write to sales@haute-living.com</p>
          </div>
          <div
            v-if="ifNotAvailableProducts.length > 0"
            class="flex flex-col md:flex-row space-x-0 md:space-x-3 lg:space-x-8"
          >
            <article
              v-for="(item, idx) in ifNotAvailableProducts"
              class="w-full mb-8 md:w-3/12 md:mb-0 more--height"
              :key="idx"
            >
              <figure class="relative block || image offsetImageFigure">
                <img
                  :src="item.images.urls[0]"
                  :alt="item.alt"
                  class="object-cover w-full h-full v-lazy-image v-lazy-image-loaded"
                />
              </figure>
              <header class="mt-4 | md:mt-5">
                <h5 class="mb-1 text-xs leading-snug text-gray-600 tracking-wider">{{
                  item.title
                }}</h5>
                <h4 class="leading-snug || indicator">
                  <span class="inline align-middle | md:text-xl">{{ item.text }}</span>
                </h4>
              </header>
            </article>
          </div>
        </div>
      </div>

      <div v-else>
        <div
          v-if="getPaginatedCuratedProducts.length > 0"
          class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6 mb-16"
        >
          <div class="transition--all pt-1 md:pt-0 w-full">
            <div class="w-full mb-0 md:mb-8">
              <h2 class="block w-full mb-0 font-cormorant font-bold">Featured products</h2>
            </div>
            <ProductTileGridCurated
              class="md:pt-1"
              :hide-price="true"
              :products="getPaginatedCuratedProducts"
              :destination="'ProductsListingPage'"
            />
          </div>
        </div>

        <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6 mb-16">
          <div v-if="getPaginatedCuratedProducts.length > 0" class="w-full mb-8">
            <h2 class="block w-full mb-0 font-cormorant font-bold">Full Collection</h2>
          </div>
          <div class="hidden appendFilters w-full lg:flex-row items-center lg:flex mb-2">
            <div
              class="filterHidden mr-12 flex flex-row flex-wrap items-center cursor-pointer"
              :class="`${!moreWidth ? 'ml-0 lg:ml-1' : ''}`"
              @click="showSidebarFilters"
            >
              <span class="mr-2 font-medium text-sm leading-tight"
                >{{ `${moreWidth ? 'Show' : 'Hide'}` }} Filters</span
              >
              <FilterIcon />
            </div>
            <div class="quickShipFilter" :class="`${clicked ? 'active' : ''}`">
              Quick Ship
              <span @click="quickShipChecked"></span>
            </div>
            <span
              v-if="!getIsLoading"
              class="block text-center mr-8 text-sm text-gray-600 || mobile"
              aria-current="true"
              aria-label="current products"
              v-html="shownQuantity + ' items selected'"
            ></span>
            <div v-if="externalLink" class="externalLinkWrapper">
              <a :href="externalLink" target="_blank">
                {{ externalLinkText }}
                <ExternalIcon width="18px" height="18px" />
              </a>
            </div>
            <div class="filterButtons">
              <ul>
                <li v-for="(filter, idx) in getSelectedOption" :key="idx">
                  <span @click="remoteSelectedFilter(filter.index)">
                    {{ filter.label }}
                    <IconClose width="12px" height="12px" />
                  </span>
                </li>
              </ul>
            </div>
            <button
              v-if="getSelectedOption.length > 0"
              class="font-normal text-base text-black underline hover:no-underline"
              :class="getUsedFilters.length === 0 ? 'text-black' : ''"
              @click="clearAllFilters()"
            >
              Clear all
            </button>
            <div class="hidden lg:flex justify-end rightFilter">
              <BaseFilterSelect
                label="Sort by"
                :options="getSortOptions"
                :value="getSort"
                class="lg:pr-6 mt-3"
                @change="setSort($event)"
              />
            </div>
          </div>
          <div>
            <div
              class="static flex flex-col md:flex-row md:relative flex-wrap overflow-hidden mb-12"
              :class="filterBarOpen ? 'z-99' : ''"
            >
              <!-- <div
              v-if="moreWidth"
              class="filterActionBtn more--tops"
              @click="moreWidth = !moreWidth"
            >
              <span>+Filter</span>
            </div> -->
              <ProductFilter
                :getIsLoading="getIsLoading"
                :open="filterBarOpen"
                :more-width="moreWidth"
                :giveOpacity="giveOpacity"
                @toggle="filterBarOpen = $event"
                @showSidebarFilters="showSidebarFilters"
                :moreWidth="moreWidth"
              />
              <div
                v-if="filterBarOpen"
                class="z-20 absolute inset-0 lg:hidden bg-black bg-opacity-25"
                @click="filterBarOpen = false"
              ></div>
              <div
                class="transition--all pt-1 md:pt-0"
                :class="`${moreWidth ? 'w-full' : 'w-full lg:w-9/12 xl:w-4/5'}`"
              >
                <!-- Pagination indicator small screens -->
                <!-- <nav ref="pagination" aria-label="Product pagination" class="block md:hidden mt-4">
                <span
                  v-if="!getIsLoading"
                  class="block text-center md:hidden text-sm text-gray-500 || mobile"
                  aria-current="true"
                  aria-label="current products"
                  v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
                />
              </nav> -->
                <ProductTileGrid
                  class="md:pt-1"
                  :moreWidth="moreWidth"
                  :hide-price="true"
                  :destination="'ProductsListingPage'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:pb-12">
        <div class="py-8 mx-auto lg:max-w-screen-2xl mt-8 w-full px-4 md:px-6">
          <div ref="alphaSection" class="w-full alphaSection mb-8 lg:mb-14">
            <ul
              class="flex flex-wrap flex-row w-full justify-between border-t border-b border-gray-300 py-5 headingList"
            >
              <li v-for="(item, x) in getStructuredItems" :key="x">
                <span
                  class="font-semibold uppercase text-black block m-0 text-sm cursor-pointer hover:text-red-700"
                  @click="scrollToElementOnClick(item.heading)"
                  >{{ item.heading }}</span
                >
              </li>
            </ul>
          </div>

          <BrandList :trade-only="tradeOnlyBrandsAll" />
        </div>
      </div>
    </article>
  </main>
</template>

<style scoped lang="postcss">
  .more--height {
    .offsetImageFigure {
      height: 450px;

      img {
        min-width: 100%;
      }
      @media (max-width: 1024px) {
        height: 400px;
      }
    }
  }

  .externalLinkWrapper {
    margin-right: 30px;

    a {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;

      svg {
        margin-left: 10px;
      }

      &:hover {
        color: #ca1818;

        svg {
          path {
            fill: #ca1818;
          }
        }
      }
    }
  }

  .quickShipFilter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    height: 22px;
    margin-right: 50px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;

    span {
      position: relative;
      width: 40px;
      height: 100%;
      margin-left: 10px;
      background: #6f6f6f;
      border-radius: 50px;
      transition: all 0.25 ease-in-out;

      &::before {
        position: absolute;
        top: 2.11px;
        left: 2.11px;
        width: 17.78px;
        height: 17.78px;
        content: '';
        background: #fff;
        border-radius: 100%;
        transition: all 0.25 ease-in-out;
      }
    }

    &.active {
      span {
        background: #000;

        &::before {
          left: calc(100% - 19.89px);
        }
      }
    }
  }

  .rightFilter {
    width: 210px;
    margin-left: auto;
  }

  .appendFilters {
    position: relative;
    top: 0;
    z-index: 99;
    width: 100%;
  }

  .filterButtons {
    display: flex;
    width: auto;
    max-width: 600px;
    margin-right: 20px;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -10px;

      li {
        margin-right: 10px;
        margin-bottom: 10px;

        span {
          position: relative;
          display: inline-flex;
          align-items: center;
          height: 26px;
          padding: 0 37px 0 15px;
          font-family: 'Unica77', sans-serif;
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          color: #000;
          cursor: pointer;
          border: 1px solid #000;

          svg {
            position: absolute;
            top: 50%;
            right: 18px;
            transform: translateY(-50%);
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .filterActionBtn {
    position: absolute;
    top: 75px;
    left: -14px;
    z-index: 999;
    cursor: pointer;
    transform: rotate(-90deg);

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #000;
    }
  }

  .alphaSection {
    &.fixed {
      position: fixed;
      left: 0;
      z-index: 1050;
      background: #fff;

      ul {
        padding-right: 24px;
        padding-left: 24px;
      }

      &.bigFixed {
        top: 128px;
      }

      &.smallFixed {
        top: 72px;
      }
    }
    @media (max-width: 1024px) {
      &.fixed {
        &.bigFixed {
          top: 102px;
        }

        &.smallFixed {
          top: 56px;
        }
      }

      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #6f6f6f;
        border-radius: 100px;
        outline: none;
      }
    }
    @media (max-width: 992px) {
      &.fixed {
        &.bigFixed {
          top: 80px;
        }
      }
    }
    @media (max-width: 767px) {
      &.fixed {
        ul {
          padding-right: 16px;
          padding-left: 16px;
        }
      }
    }
  }

  .headingList {
    @media (max-width: 767px) {
      width: 1000px;

      &.alphaSectionByCountry {
        width: 1400px;
      }
    }

    &.alphaSectionByCountry {
      li {
        span {
          font-size: 13px;
          text-transform: initial;
        }
      }
      @media (max-width: 1350px) {
        justify-content: flex-start;

        li {
          margin: 0 5px;
        }
      }
    }
  }

  .transition--all {
    transition: all 0.25s ease-in-out;
  }
  @media (max-width: 1024px) {
    .stickySidebar {
      margin-left: 0 !important;
    }
  }
</style>
