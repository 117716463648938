<script>
  import VLazyImage from 'v-lazy-image'
  // eslint-disable-next-line import/no-relative-parent-imports
  import { createNamespacedHelpers } from 'vuex'
  import { formatCurrency } from '@/utilities/strings'
  import BaseTagGroup from '@/components/BaseTagGroup'

  const { mapGetters, mapActions } = createNamespacedHelpers('products')

  /** A reusable product tile preview. Makes use of VLazyImage to load preview images when they are in the viewport and
   * provides a loading animated SVG in base64 before the image loads to prevent layout jank.
   * See: https://github.com/alexjoverm/v-lazy-image */

  export default {
    name: 'ProductTile',

    components: {
      VLazyImage,
      BaseTagGroup,
    },

    props: {
      /** The product's URL */
      url: {
        type: String,
        required: true,
      },
      /** The product's main title */
      title: {
        type: String,
        required: true,
      },
      types: {
        type: String,
        default: '',
      },
      /** The product's primary image as an object including params for alternate text, low rez and high rez image URLs */
      image: {
        type: Object,
        required: false,
        default: () => ({
          alt: '',
          urls: [],
        }),
      },
      /** The product's hover image as an object including params for alternate text, low rez and high rez image URLs */
      hoverImage: {
        type: Object,
        required: false,
        default: () => ({
          alt: '',
          urls: [],
        }),
      },
      /** The product's brand name */
      brand: {
        type: String,
        required: false,
        default: '',
      },
      /** The products prices object with the lowest trade discount and normal prices, and multiple prices flag */
      prices: {
        type: Object,
        required: false,
        default: () => ({
          prices: {
            normal: 0,
            trade: 0,
          },
          multiple: false,
        }),
      },
      /** Whether or not to hide the price line all together. **/
      hidePrice: {
        type: Boolean,
        required: false,
        default: false,
      },
      /** Whether or not to hide the label "Price on Request" **/
      hidePriceOnRequestLabel: {
        type: Boolean,
        required: false,
        default: false,
      },
      /** An array of tag names associated to the product */
      tags: {
        type: Array,
        required: false,
        default: () => [],
      },
      dragging: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: '',
      },
      destination: {
        type: String,
        default: '',
      },
      seoDescription: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        delta: 10,
        startX: 0,
        startY: 0,
        drags: false,
      }
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getTradeAccount']),
      /** Displays the correct price string based on trade account or normal prices */
      priceString: function () {
        let priceStr = 'Price on request'
        const price = this.getTradeAccount
          ? parseFloat(this.prices.prices.trade)
          : parseFloat(this.prices.prices.normal)
        if (price > 0 && this.prices.multiple) {
          priceStr = 'From ' + this.formatCurrency(price, 0)
        }
        if (price > 0 && !this.prices.multiple) {
          priceStr = this.formatCurrency(price, 0)
        }
        return priceStr
      },
      hidePriceString: function () {
        return (
          this.hidePrice ||
          (this.hidePriceOnRequestLabel && this.priceString === 'Price on request')
        )
      },
      /** Creates the image srcset string based on the high and low rez images provided */
      srcSet: function () {
        let srcString = ''
        if (this.image.urls[0] && this.image.urls[1]) {
          srcString = this.image.urls[0] + ' 1x, ' + this.image.urls[1] + ' 2x '
        }
        return srcString
      },
      /** Create the image sizes string */
      srcSizes: function () {
        return '50vw, (min-width: 768px) 33vw, (min-width: 1024px) 25vw, (min-width: 1560px) 20vw, (min-width: 1920px) 17vw'
      },
      /** Create the base64 SVG loading image */
      srcPlaceholder: function () {
        return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMTUgMzE1IiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0Ij4KICA8cmVjdCBjbGlwLXBhdGg9InVybCgjY2xpcFBhdGgtMTMpIiB4PSIwIiB5PSIwIiB3aWR0aD0iMzE1IiBoZWlnaHQ9IjMxNSIgc3R5bGU9ImZpbGw6IHVybCgmcXVvdDsjZ3JhZGllbnQtMTMmcXVvdDspOyI+PC9yZWN0PgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwUGF0aC0xMyI+CiAgICAgIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIzMTUiIGhlaWdodD0iMzE1Ij48L3JlY3Q+CiAgICA8L2NsaXBQYXRoPgogICAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkaWVudC0xMyI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmMGYwZjAiPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIgdmFsdWVzPSItMjsgMSIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2UwZTBlMCI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IiB2YWx1ZXM9Ii0xLjU7IDEuNSIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmMGYwZjAiPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIgdmFsdWVzPSItMTsgMiIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgPC9saW5lYXJHcmFkaWVudD4KICA8L2RlZnM+Cjwvc3ZnPgo='
      },
    },

    methods: {
      /** Vuex actions */
      ...mapActions(['saveBrowsingSnapshot']),
      /** String manipulation library method to format numbers as currency */
      formatCurrency,
      saveBrowser(event) {
        event.preventDefault()
        this.saveBrowsingSnapshot()
        window.open(event.target.href)
      },
      onMouseDown(event) {
        this.drags = true
        this.startX = event.pageX
        this.startY = event.pageY
      },
      onMouseUp(event) {
        const deltaX = event.pageX - this.startX
        const deltaY = event.pageY - this.startY
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          this.drags = true
        } else {
          this.drags = false
        }
      },
    },
  }
</script>

<template>
  <a
    :href="url"
    class="block focus:outline-none || anchor relative cursor-pointer"
    :aria-label="title"
    @click="saveBrowsingSnapshot"
  >
    <article class="relative">
      <figure
        v-if="image.urls.length"
        class="relative block border border-gray-300 mb-3 image"
        :class="destination === 'curated' ? 'offsetImageFigure' : 'md:mb-6'"
      >
        <VLazyImage
          :srcset="srcSet"
          :src="image.urls[0]"
          :src-placeholder="srcPlaceholder"
          :sizes="srcSizes"
          :alt="image.alt"
        />
        <img
          v-if="hoverImage.urls.length"
          :src="hoverImage.urls[0]"
          alt=""
          class="hover-image block absolute inset-0 object-center object-cover w-full h-full z-10 pointer-events-none"
        />
      </figure>

      <div v-if="types === 'Products' || types === ''" class="md:space-y-1">
        <!-- <h3 class="text-black font-medium text-xs tracking-normal leading-3 uppercase mb-2">
          {{ brand }}
        </h3>
        <h4 class="text-gray-600 text-sm leading-none">{{ title }}</h4> -->
        <div v-if="destination === 'ProductsListingPage'">
          <h3 class="text-black font-medium text-xs tracking-normal leading-3 uppercase mb-2">
            {{ brand }}
          </h3>
          <h4
            class="text-black text-sm font-unica77 font-normal tracking-wider uppercase leading-none"
            >{{ title }}</h4
          >
        </div>
        <div v-else-if="destination === 'curated'">
          <h4
            class="leading-snug text-base font-unica77 font-medium tracking-wider uppercase text-black mb-2"
            >{{ title }}</h4
          >
        </div>
        <div v-else-if="destination === 'ProductDetailPage'">
          <h3
            class="leading-snug text-base font-unica77 font-medium tracking-wider text-black mb-2"
          >
            {{ brand }}
          </h3>
          <h4 class="text-black font-unica77 font-normal tracking-wider text-lg leading-5">{{
            title
          }}</h4>
        </div>
        <div v-else>
          <h3
            class="leading-snug text-base font-unica77 font-normal tracking-wider text-black mb-2"
          >
            {{ brand }}
          </h3>
          <h4 class="text-black font-unica77 font-medium tracking-wider text-lg leading-5">{{
            title
          }}</h4>
        </div>
      </div>

      <div v-else class="md:space-y-1">
        <h3 class="leading-snug text-base text-gray-600">
          {{ brand }}
        </h3>
        <h4
          class="text-black font-medium text-lg font-unica77 font-medium tracking-wider leading-5 mb-2"
          >{{ title }}</h4
        >
        <p v-if="seoDescription" class="pt-5 text-sm leading-tight block"
          >{{ seoDescription }}...</p
        >
      </div>

      <div v-if="tags.length" class="hidden absolute z-20 top-5 right-2 w-2/3 | tags">
        <BaseTagGroup :tags="tags" />
      </div>
    </article>
  </a>
</template>

<style scoped lang="postcss">
  .productLink {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
  }

  .offsetImageFigure {
    height: 440px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 992px) {
      height: 200px;
    }
  }

  .productTileLink {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
  }

  .image img {
    @apply min-w-full;
  }

  .image::after {
    content: '';
    @apply absolute inset-0 block bg-gray-900 opacity-0 transition-opacity duration-300;
  }

  .indicator {
    width: 6px;
    height: 6px;
    border-radius: 3px;
  }

  .anchor {
    .hover-image {
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s linear;
    }
    @media screen and (min-width: 800px) {
      &:hover {
        .hover-image {
          visibility: visible;
          opacity: 1;
        }

        .indicator {
          @apply opacity-100;
        }
      }
    }
  }
</style>
